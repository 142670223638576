<template>
  <div>
    <div>
      <v-container fluid>
        <loading
          :active.sync="isLoading"
          :can-cancel="false"
          loader="dots"
          color="#007bff"
          :height="45"
          :width="75"
          :is-full-page="fullPage"
        ></loading>
        <v-row>
          <v-col cols="9">
            <v-row>
              <v-col>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      no-title
                      v-model="sdate"
                      label="From Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="sdate"
                    @input="menu2 = false"
                    no-title
                    scrollable
                    ref="datepicker"
                    event-color="white"
                    :max="max"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col>
                <v-menu
                  v-model="menu3"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      no-title
                      v-model="edate"
                      label="To Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="edate"
                    @input="menu3 = false"
                    no-title
                    scrollable
                    ref="datepicker"
                    event-color="white"
                    :max="max"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3">
            <v-btn
              small
              color="primary my-4"
              @click.prevent="allDailyStatusReport"
            >
              Search
            </v-btn>
            <v-btn small color="primary my-4" @click="reset"> Reset </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-data-table
      :headers="headers"
      :items="dailyStateData"
      class="elevation-1"
      :sort-desc.sync="sortDesc"
      :sort-by.sync="options"
      :items-per-page="perpage"
      hide-default-footer
    >
      <template v-slot:body="{ items }">
        <tbody v-for="item in items" :key="item.name">
          <tr>
            <td class="table_border">
              <v-row>
                <v-col cols="9" sm="9" md="9"
                  ><span class="font-weight-bold"
                    >Consumer Panelist:</span
                  ></v-col
                >
                <v-col cols="3" sm="3" md="3"
                  ><span>{{ item.email.consumer_panelist }}</span></v-col
                >
              </v-row>
            </td>
            <td class="table_border">
              <v-row>
                <v-col cols="9" sm="9" md="9"
                  ><span class="font-weight-bold"
                    >Citi FTP Total Count:</span
                  ></v-col
                >
                <v-col cols="3" sm="3" md="3"
                  ><span>{{ item.direct_mail.Moved_To_Citi_FTP }}</span></v-col
                >
              </v-row>
            </td>
            <td class="table_border">
              <v-row>
                <v-col cols="9" sm="9" md="9"
                  ><span class="font-weight-bold">Online Display:</span></v-col
                >
                <v-col cols="3" sm="3" md="3"
                  ><span>{{ item.digital_ads.Online_Display }}</span></v-col
                >
              </v-row>
            </td>
            <td class="table_border">
              <v-row>
                <v-col cols="9" sm="9" md="9"
                  ><span class="font-weight-bold"
                    >Total Processed:</span
                  ></v-col
                >
                <v-col cols="3" sm="3" md="3"
                  ><span>{{
                    item.bi_science.Total_Processed_Records
                  }}</span></v-col
                >
              </v-row>
            </td>
            <td class="table_border">
              <v-row>
                <v-col cols="9"
                  ><span class="font-weight-bold">Engagement:</span></v-col
                >
                <v-col cols="3"
                  ><span>{{ item.email_alert.Engagement }}</span></v-col
                >
              </v-row>
            </td>
            <td class="table_border">
              <v-row>
                <v-col cols="9" sm="9" md="9"
                  ><span class="font-weight-bold">Total Entry ID:</span></v-col
                >
                <v-col cols="3" sm="3" md="3"
                  ><span>{{ item.entry_id.Total_Entry_ID }}</span></v-col
                >
              </v-row>
            </td>
            <td class="table_border">
              <v-row>
                <v-col cols="9" sm="9" md="9"
                  ><span class="font-weight-bold">User Visitors:</span></v-col
                >
                <v-col cols="3" sm="3" md="3"
                  ><span>{{ item.visitors.User_Visitors }}</span></v-col
                >
              </v-row>
            </td>
            <td></td>
          </tr>
          <tr>
            <td class="table_border">
              <v-row>
                <v-col cols="9" sm="9" md="9"
                  ><span class="font-weight-bold"
                    >Provider Panelist:</span
                  ></v-col
                >
                <v-col cols="3" sm="3" md="3"
                  ><span>{{ item.email.provider_panelist }}</span></v-col
                >
              </v-row>
            </td>
            <td class="table_border"></td>
            <td class="table_border">
              <v-row>
                <v-col cols="9" sm="9" md="9"
                  ><span class="font-weight-bold">
                    Search Engine marketings:</span
                  ></v-col
                >
                <v-col cols="3" sm="3" md="3"
                  ><span>{{
                    item.digital_ads.Search_Engine_marketings
                  }}</span></v-col
                >
              </v-row>
            </td>
            <td class="table_border"></td>
            <td class="table_border">
              <v-row>
                <v-col cols="9" sm="9" md="9"
                  ><span class="font-weight-bold">Triggered:</span></v-col
                >
                <v-col cols="3" sm="3" md="3"
                  ><span>{{ item.email_alert.Triggered }}</span></v-col
                >
              </v-row>
            </td>
            <td class="table_border"></td>
            <td class="table_border">
              <v-row>
                <v-col cols="9" sm="9" md="9"
                  ><span class="font-weight-bold">Guest Visitors:</span></v-col
                >
                <v-col cols="3" sm="3" md="3"
                  ><span>{{ item.visitors.Guest_Visitors }}</span></v-col
                >
              </v-row>
            </td>
            <td></td>
          </tr>
          <tr>
            <td class="table_border">
              <v-row>
                <v-col cols="9" sm="9" md="9"
                  ><span class="font-weight-bold"
                    >Mortage Broker Panelist:</span
                  ></v-col
                >
                <v-col cols="3" sm="3" md="3"
                  ><span>{{ item.email.Mortage_Broker_Panelist }}</span></v-col
                >
              </v-row>
            </td>
            <td class="table_border"></td>
            <td class="table_border">
              <v-row>
                <v-col cols="9" sm="9" md="9"
                  ><span class="font-weight-bold">Online Video:</span></v-col
                >
                <v-col cols="3" sm="3" md="3"
                  ><span>{{ item.digital_ads.Online_Video }}</span></v-col
                >
              </v-row>
            </td>
            <td class="table_border"></td>
            <td class="table_border"></td>
            <td class="table_border"></td>
            <td class="table_border"></td>
            <td>
              <!-- Remove Slice After Right api-->
              <v-row no-gutters class="mb-3">{{
                item.date.slice(0, 10)
              }}</v-row>
            </td>
          </tr>
          <tr>
            <td class="table_border">
              <v-row>
                <v-col cols="9" sm="9" md="9"
                  ><span class="font-weight-bold"
                    >Producer Panelist:</span
                  ></v-col
                >
                <v-col cols="3" sm="3" md="3"
                  ><span>{{ item.email.producer_panelist }}</span></v-col
                >
              </v-row>
            </td>
            <td class="table_border"></td>
            <td class="table_border"></td>
            <td class="table_border"></td>
            <td class="table_border"></td>
            <td class="table_border"></td>
            <td class="table_border"></td>
            <td></td>
          </tr>
          <tr>
            <td class="table_border table_bootm">
              <v-row>
                <v-col cols="9" sm="9" md="9"
                  ><span class="font-weight-bold">Total:</span></v-col
                >
                <v-col cols="3" sm="3" md="3"
                  ><span>{{ item.email.total }}</span></v-col
                >
              </v-row>
            </td>
            <td class="table_border table_bootm"></td>
            <td class="table_border table_bootm"></td>
            <td class="table_border table_bootm"></td>
            <td class="table_border table_bootm"></td>
            <td class="table_border table_bootm"></td>
            <td class="table_border table_bootm"></td>
            <td class="table_bootm"></td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <div class="text-center pt-4 pb-4">
      <Pagination
        :isloading="isLoading"
        :startRecord="startRecord"
        :currentPage="currentPage"
        :lastPage="lastPage"
        :lastRecord="lastRecord"
        :totRecords="totRecords"
        :isCurrentPageClass="isCurrentPageClass"
        :perpage="perpage"
        :getLastPageClass="getLastPageClass"
        :totPage="totPage"
        :getPages="getPages"
        @handlePerPage="handlePerPage"
        @paginate="paginate"
        @last="last"
        @getDataByPage="getDataByPage"
        :showPerPage="showPerPage"
      />
    </div>
  </div>
</template>
<script>
import Pagination from "./component/Pagination.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
export default {
  components: { Pagination, Loading },
  name: "DailyStatusReport",
  data: () => ({
    max: moment(new Date()).format("YYYY-MM-DD"),
    isLoading: true,
    fullPage: true,
    sort: "",
    headers: [
      {
        text: "Email",
        value: "email",
        width: "200px",
        align: "center",
        sortable: false,
      },
      {
        text: "Direct Mail",
        value: "direct_mail",
        width: "200px",
        align: "center",
        sortable: false,
      },
      {
        text: "Digital Ads",
        value: "digital_ads",
        width: "200px",
        align: "center",
        sortable: false,
      },
      {
        text: "Digital Ads(Bi-science)",
        value: "bi_science",
        width: "200px",
        align: "center",
        sortable: false,
      },
      {
        text: "Email Alert",
        value: "email_alert",
        align: "center",
        sortable: false,
        width: "200px",
      },
      {
        text: "Entry ID",
        value: "entry_id",
        align: "center",
        sortable: false,
        width: "200px",
      },
      {
        text: "Visitors",
        value: "visitors",
        align: "center",
        sortable: false,
        width: "200px",
      },
      {
        text: "Date",
        value: "date",
        width: "100px",
        align: "center",
      },
    ],
    dailyStateData: [],
    isLoading: false,
    sdate: moment().subtract(1, "days").format("YYYY-MM-DD"),
    edate: moment().subtract(1, "days").format("YYYY-MM-DD"),

    //Other Code Remove if need:
    checkbox: false,
    affinity: [],
    checkbox1: false,
    dialog: false,
    dialog1: false,
    dialog2: false,
    menu2: false,
    menu3: false,
    query: "",
    checked: false,
    //pagination code
    // perpage: 20,
    // totRecords: null,
    page: 1,
    isLoading: false,
    // fullPage: true,
    showPerPage: true,
    subject_length: 125,
    sortDes: "asc",
    options: "date",
    sortDesc: true,
    sort: "asc",
    ///
    orders: [
      { key: "id", val: true },
      { key: "insert_update_date", val: true },
    ],
    enableSorting: false,
    show_duplicate: false,
    dmdupObject: false,
  }),
  mounted() {
    this.allDailyStatusReport();
  },
  watch: {
    options: {
      async handler(o) {
        let view = this;
        view.$store.dispatch("DalilyStatus/setPage", parseInt(1));
        view.sortDesc = !view.sortDesc;
        if (view.sortDesc) {
          view.sort = await "desc";
        } else {
          view.sort = await "asc";
        }
        view.allDailyStatusReport();
      },
    },
  },
  computed: {
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    perpage: {
      get: function () {
        return this.$store.getters["DalilyStatus/getPerPage"];
      },
      set: function () {},
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    totRecords: function () {
      var store = this.$store;
      return store.getters["DalilyStatus/getTotalDmduplicates"] == ""
        ? 0
        : store.getters["DalilyStatus/getTotalDmduplicates"];
    },
    currentPage: function () {
      return this.$store.getters["DalilyStatus/getCurrentPage"];
    },
    lastPage: function () {
      return this.$store.getters["DalilyStatus/getLastPage"];
    },
    ///////
    // total_count: function () {
    //         return this.dedup.duplicates.split(',').length
    //     },
  },
  methods: {
    //DmDuplicate code below:
    dedupClass: function (i) {
      return parseInt(i) % 2 === parseInt(0)
        ? "grey lighten-2"
        : "grey lighten-5";
    },
    showDuplicates: function (dmdupObject) {
      this.dmdupObject = dmdupObject;
      this.show_duplicate = !this.show_duplicate;
    },
    hidePopup: function (flag) {
      if (flag) {
        this.show_duplicate = false;
      }
    },
    fetchDuplicates: function (flag) {
      if (flag) {
        this.show_duplicate = false;
      }
    },
    ellespingSubject: function (subject) {
      return subject != null
        ? subject.length > this.subject_length
          ? `${subject.substring(0, this.subject_length)}...`
          : subject
        : "";
    },
    search: function () {
      this.allDailyStatusReport();
    },
    checkIfOperationExistForModule(type) {
      return this.$utils.checkIfOperationExistForModule(this, type);
    },
    //pagination methods
    getDataByPage(page) {
      let view = this;
      view.$store
        .dispatch("DalilyStatus/setPage", page)
        .then(function () {
          return view.$store.dispatch("DalilyStatus/setIsLoading", true);
        })
        .then(function () {
          view.allDailyStatusReport();
        })
        .catch(function (e) {
          console.log(e);
        });
    },
    handlePerPage(p) {
      let view = this;
      view.$store
        .dispatch("DalilyStatus/setPerPage", p)
        .then(function () {
          return view.$store.dispatch("DalilyStatus/setPage", parseInt(1));
        })
        .then(function () {
          return view.$store.dispatch("DalilyStatus/setIsLoading", true);
        })
        .then(function () {
          view.allDailyStatusReport();
        })
        .catch(function (e) {
          console.log(e);
        });
    },
    paginate(n) {
      let view = this;
      if (
        parseInt(n) === parseInt(0) &&
        parseInt(this.currentPage) === parseInt(1)
      ) {
        return;
      }
      if (parseInt(n) === parseInt(-1)) {
        if (parseInt(this.currentPage) <= parseInt(1)) {
          return;
        }
      }
      if (parseInt(n) === parseInt(1)) {
        if (parseInt(this.currentPage) >= parseInt(this.lastPage)) {
          return;
        }
      }
      let myPromise = new Promise(function (resolve, reject) {
        resolve(
          view.$store.dispatch(
            "DalilyStatus/setPage",
            n === 0 ? 1 : parseInt(view.currentPage) + parseInt(n)
          )
        );
        reject("Error: on set page");
      });
      myPromise
        .then(function () {
          view.$store.getters["DalilyStatus/getIsFromSearch"]
            ? view.allDailyStatusReport()
            : view.allDailyStatusReport();
        })
        .catch(function (e) {
          console.log(e);
        });
    },
    last() {
      let view = this;
      if (parseInt(view.lastPage) === parseInt(view.currentPage)) {
        return;
      }
      view.$store
        .dispatch("DalilyStatus/setPage", view.lastPage)
        .then(function () {
          view.$store.dispatch("DalilyStatus/setIsLoading", true);
        })
        .then(function () {
          view.allDailyStatusReport();
        })
        .catch(function (e) {
          console.log(e);
        });
    },
    reset() {
      var view = this;
      view.sdate = moment().subtract(2, "days").format("YYYY-MM-DD");
      view.edate = moment().subtract(1, "days").format("YYYY-MM-DD");
      view.$store.dispatch("DalilyStatus/setPerPage", 10);
      view.$store.dispatch("DalilyStatus/setPage", parseInt(1));
      // view.$store.dispatch("DalilyStatus/setResponsePayload", []);
      // view.$store.dispatch("DalilyStatus/setHasNext", false);
      // view.$store.dispatch("DalilyStatus/setTotalDmduplicates", "");
      // view.$store.dispatch("DalilyStatus/setLastPage", 0);
      view.allDailyStatusReport();
    },

    /////-
    allDailyStatusReport() {
      let view = this;
      view.isLoading = true;
      //Take Eg from Here
      let sdate = view.sdate;
      let edate = view.edate;
      let date1 = new Date(edate).getTime();
      let date2 = new Date(sdate).getTime();
      view.perpage =
        view.$store.getters["DalilyStatus/getRequestPayload"].perpage;
      if (date1 >= date2) {
        view.$store
          .dispatch("DalilyStatus/setIsFromSearch", true)
          .then(function () {
            return view.$store.dispatch(
              "DalilyStatus/setStartTime",
              new Date().getTime()
            );
          })
          .then(function () {
            return view.$store.dispatch("DalilyStatus/setIsLoading", true);
          })
          .then(function () {
            return view.$store.dispatch("DalilyStatus/setResponsePayload", []);
          })
          .then(function () {
            view.dailyStateData = [];
            view
              .$fetch({ requiresAuth: true, operation: "add", vueScope: view })

              .post(
                view.$url("DAILY_STATUS"),
                // "https://api8-uat.competiscan.com/admin-report/v1/daily-status-report",
                JSON.stringify({
                  sdate: sdate,
                  edate: edate,
                  // payload: view.$store.getters["DalilyStatus/getRequestPayload"],
                  page: view.$store.getters["DalilyStatus/getRequestPayload"]
                    .page,
                  records_per_page: view.perpage,
                  sort: view.sort,
                }),
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then(function (res) {
                view.isLoading = false;
                let response = res.data;

                view.$store.dispatch(
                  "DalilyStatus/setEndTime",
                  new Date().getTime()
                );
                view.$store.dispatch("DalilyStatus/setIsLoading", false);
                if (
                  typeof response !== "undefined" &&
                  response.error === undefined
                ) {
                  for (let i = 0; i < response.emailaleart.length; i++) {
                    view.dailyStateData.push({
                      ["email"]: {
                        ["Mortage_Broker_Panelist"]:
                          response.emailaleart[i].mortgagebrokerpanelist,
                        ["consumer_panelist"]:
                          response.emailaleart[i].consumerpanelist,
                        ["producer_panelist"]:
                          response.emailaleart[i].producserpanelist,
                        ["provider_panelist"]:
                          response.emailaleart[i].providerpanelist,
                        ["total"]: response.emailaleart[i].total,
                      },
                      ["direct_mail"]: {
                        ["Moved_To_Citi_FTP"]:
                          response.emailaleart[i].directmail,
                      },
                      ["digital_ads"]: {
                        ["Online_Display"]:
                          response.emailaleart[i].onlinedisplay,
                        ["Search_Engine_marketings"]:
                          response.emailaleart[i].searchengine,
                        ["Online_Video"]: response.emailaleart[i].onlinevideo,
                      },
                      ["bi_science"]: {
                        ["Total_Processed_Records"]:
                          response.emailaleart[i].digitaldashboardrecords,
                      },
                      ["email_alert"]: {
                        ["Engagement"]: response.emailaleart[i].engagement,
                        ["Triggered"]: response.emailaleart[i].emailtrigger,
                      },
                      ["entry_id"]: {
                        ["Total_Entry_ID"]: response.emailaleart[i].entryid,
                      },
                      ["visitors"]: {
                        ["User_Visitors"]: response.emailaleart[i].uservisitor,
                        ["Guest_Visitors"]:
                          response.emailaleart[i].guestvisitor,
                      },
                      ["date"]: response.emailaleart[i].date,
                    });
                  }
                }
                typeof response.payload !== "undefined" &&
                Array.isArray(response.emailaleart)
                  ? view.$store.dispatch(
                      "DalilyStatus/setResponsePayload",
                      response.emailaleart
                    )
                  : [];
                // typeof response.hasNext !== "undefined"
                //   ? view.$store.dispatch(
                //       "DalilyStatus/setHasNext",
                //       response.hasNext
                //     )
                //   : "";
                typeof response.total_records !== "undefined"
                  ? view.$store.dispatch(
                      "DalilyStatus/setTotalDmduplicates",
                      response.total_records
                    )
                  : "";
                typeof response.total_pages !== "undefined"
                  ? view.$store.dispatch(
                      "DalilyStatus/setLastPage",
                      response.total_pages
                    )
                  : "";
              })
              .catch(function (e) {
                view.$store.dispatch("DalilyStatus/setResponsePayload", []);
                // view.$store.dispatch("DalilyStatus/setHasNext", false);
                view.$store.dispatch("DalilyStatus/setTotalDmduplicates", "");
                view.$store.dispatch("DalilyStatus/setLastPage", 0);
                console.log(e);
              });
          });
      } else {
        view.isLoading = false;
        // view.$store.dispatch(
        //   "DalilyStatus/setResponsePayload",
        //   []
        // );
        // view.$store.dispatch("DalilyStatus/setHasNext", false);
        // view.$store.dispatch("DalilyStatus/setTotalDmduplicates", "");
        // view.$store.dispatch("DalilyStatus/setLastPage", 0);
        view.$swal.fire({
          icon: "error",
          title: "Error",
          text: "To date must be greater than From date",
        });
      }
    },
  },
};
</script>

<style scoped>
.table_border {
  /* border-bottom-style: 1px solid black; */
  border-right-style: solid;
  border-right-color: #d4d4d4;
  border-right-width: 3px;
}
.table_bootm {
  border-bottom-style: solid;
  border-bottom-color: #d4d4d4;
  border-bottom-width: 3px;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 11px !important;
  height: 48px;
}
.header-daily-status {
  font-size: 11px !important;
}
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 24px !important;
}
</style>
